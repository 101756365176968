import { VideoType } from "../types/models/Media/Video"
import { NotificationType } from "../types/models/Notification"
import { PostTimeLineType } from "../types/models/Post"
import { TeamDataType } from "../types/models/Team"
import 'react-redux'
import { CommentType } from "../types/models/Comment"

export type AppState = {
    app: GlobalStateType
}

export type GlobalStateType = {
    teamPostList: PostTimeLineType[],
    followSportsPostList: PostTimeLineType[],
    userPostList: PostTimeLineType[],

    searchResultList: PostTimeLineType[],
    searchResultNextURL: string,

    commentSearchResultList: CommentType[],
    commentSearchResultNextURL: string,


    myPageNextPostURL: string,
    userNotificationList: [],
    teamNotificationList: [],
    teamNotificationNextURL: string,
    commentFormFile: File | null,
    commentFormTrimmedVideo: Blob | null,
    commentFormVisible: boolean,
    savedVideo: VideoType | null,
    allowFetchNotificationNumber: boolean,
    badgeNumber: number
    isLoggedIn: boolean | null
    userUuid?: string
    userName: string
    hasCompletedRegistration: boolean
    teamData: TeamDataType | null
}

// ______________________________________________________
//
declare module 'react-redux' {
    interface DefaultRootState extends AppState { }
}

const initialState: GlobalStateType = {
    teamPostList: [],
    followSportsPostList: [],
    userPostList: [],

    searchResultList: [],
    searchResultNextURL: "",

    commentSearchResultList: [],
    commentSearchResultNextURL: "",

    myPageNextPostURL: "",
    userNotificationList: [],
    teamNotificationList: [],
    teamNotificationNextURL: "",
    commentFormFile: null,
    commentFormTrimmedVideo: null,
    commentFormVisible: false,
    savedVideo: null,
    allowFetchNotificationNumber: true,
    badgeNumber: 0,
    isLoggedIn: null,
    userUuid: undefined,
    userName: "",
    hasCompletedRegistration: false,
    teamData: null,
}

// Home PostList
export const updateTeamPostList = ( teamPostList: PostTimeLineType[] ) => ( {
    type: "updateTeamPostList",
    teamPostList
} )

export const updateFollowSportsPostList = ( followSportsPostList: PostTimeLineType[] ) => ( {
    type: "updateFollowSportsPostList",
    followSportsPostList
} )

// MyPage
export const updateUserPostList = ( userPostList: PostTimeLineType[] ) => ( {
    type: "updateUserPostList",
    userPostList
} )

export const updateMyPageNextPostURL = ( myPageNextPostURL: string ) => ( {
    type: "updateMyPageNextPostURL",
    myPageNextPostURL
} )

// Search
export const updateSearchResultList = ( searchResultList: PostTimeLineType[] ) => ( {
    type: "updateSearchResultList",
    searchResultList
} )

export const updateSearchResultNextURL = ( searchResultNextURL: string ) => ( {
    type: "updateSearchResultNextURL",
    searchResultNextURL
} )


export const updateCommentSearchResultList = ( commentSearchResultList: CommentType[] ) => ( {
    type: "updateCommentSearchResultList",
    commentSearchResultList
} )

export const updateCommentSearchResultNextURL = ( commentSearchResultNextURL: string ) => ( {
    type: "updateCommentSearchResultNextURL",
    commentSearchResultNextURL
} )

// Notification
export const updateUserNotificationList = ( userNotificationList: NotificationType[] ) => ( {
    type: "updateUserNotificationList",
    userNotificationList
} )

export const updateTeamNotificationList = ( teamNotificationList: NotificationType[] ) => ( {
    type: "updateTeamNotificationList",
    teamNotificationList
} )

export const updateTeamNotificationNextURL = ( teamNotificationNextURL: string ) => ( {
    type: "updateTeamNotificationNextURL",
    teamNotificationNextURL
} )

// video
export const saveVideo = ( savedVideo: VideoType ) => ( {
    type: "saveVideo",
    savedVideo
} )

//commentForm
export const setCommentFormFile = ( commentFormFile: File | null ) => ( {
    type: "setCommentFormFile",
    commentFormFile
} )

export const setCommentFormTrimmedVideo = ( commentFormTrimmedVideo: Blob | null ) => ( {
    type: "setCommentFormTrimmedVideo",
    commentFormTrimmedVideo
} )

export const setCommentFormVisible = ( commentFormVisible: boolean ) => ( {
    type: "setCommentFormVisible",
    commentFormVisible
} )

// Auth
export const setIsLoggedIn = ( isLoggedIn: boolean ) => ( {
    type: "setIsLoggedIn",
    isLoggedIn
} )

export const setUserUuid = ( userUuid: string ) => ( {
    type: "setUserUuid",
    userUuid
} )

export const setUserName = ( userName: string ) => ( {
    type: "setUserName",
    userName
} )

export const setHasCompletedRegistration = ( hasCompletedRegistration: boolean ) => ( {
    type: "setHasCompletedRegistration",
    hasCompletedRegistration
} )

export const setTeamData = ( teamData: TeamDataType | null ) => ( {
    type: "setTeamData",
    teamData
} )


// badgeNumber
export const setBadgeNumber = ( badgeNumber: number ) => ( {
    type: "setBadgeNumber",
    badgeNumber
} )

//pageVisibleState
export const setAllowFetchNotificationNumber = ( allowFetchNotificationNumber: boolean ) => ( {
    type: "setAllowFetchNotificationNumber",
    allowFetchNotificationNumber
} )

export const resetStore = () => ( {
    type: "resetStore"
} )

type actionType = {
    type: string
} & GlobalStateType

export default ( state = initialState, action: actionType ) => {

    switch ( action.type ) {
        case "updateTeamPostList":
            return { ...state, teamPostList: action.teamPostList }
        case "updateFollowSportsPostList":
            return { ...state, followSportsPostList: action.followSportsPostList }

        case "updateUserPostList":
            return { ...state, userPostList: action.userPostList }
        case "updateMyPageNextPostURL":
            return { ...state, myPageNextPostURL: action.myPageNextPostURL }

        case "updateSearchResultList":
            return { ...state, searchResultList: action.searchResultList }

        case "updateSearchResultNextURL":
            return { ...state, searchResultNextURL: action.searchResultNextURL }


        case "updateCommentSearchResultList":
            return { ...state, commentSearchResultList: action.commentSearchResultList }

        case "updateCommentSearchResultNextURL":
            return { ...state, commentSearchResultNextURL: action.commentSearchResultNextURL }


        case "updateUserNotificationList":
            return { ...state, userNotificationList: action.userNotificationList }

        case "updateTeamNotificationList":
            return { ...state, teamNotificationList: action.teamNotificationList }

        case "updateTeamNotificationNextURL":
            return { ...state, teamNotificationNextURL: action.teamNotificationNextURL }

        case "saveVideo":
            return { ...state, savedVideo: action.savedVideo }

        case "setCommentFormFile":
            return { ...state, commentFormFile: action.commentFormFile, commentFormTrimmedVideo: null }

        case "setCommentFormTrimmedVideo":
            return { ...state, commentFormTrimmedVideo: action.commentFormTrimmedVideo }
        // console.log("set")
        // let commentFormFile = action.commentFormFile
        // if( !commentFormFile )return {...state, commentFormFile:null}
        // if( commentFormFile.name.includes(".") ){
        //     return { ...state, commentFormFile:action.commentFormFile }
        // }

        // if(window.confirm("拡張子なしのこのファイルは、動画として扱われます。\nよろしいですか？")){
        //     return { ...state, commentFormFile:action.commentFormFile }
        // }
        // alert("適切な拡張子なしに変換してからアップロードしてください")
        // return { ...state }

        case "setIsLoggedIn":
            return { ...state, isLoggedIn: action.isLoggedIn }

        case "setUserUuid":
            return { ...state, userUuid: action.userUuid }

        case "setUserName":
            return { ...state, userName: action.userName }

        case "setHasCompletedRegistration":
            return { ...state, hasCompletedRegistration: action.hasCompletedRegistration }

        case "setTeamData":
            return { ...state, teamData: action.teamData }

        case "setCommentFormVisible":
            return { ...state, commentFormVisible: action.commentFormVisible }

        case "setBadgeNumber":
            return { ...state, badgeNumber: action.badgeNumber }

        case "setAllowFetchNotificationNumber":
            return { ...state, allowFetchNotificationNumber: action.allowFetchNotificationNumber }

        case "initialState":
            return { ...initialState }

        case "resetStore":
            return { ...initialState }

        default:
            return state;
    }
}
