import React from "react"
import "./src/styles/global.scss"
import "./src/styles/spinkit.scss"
import amplitude, { logEvent } from "./src/Analytics"
import { ToastProvider } from "./src/context/ToastContext"
import { ModalProvider } from "./src/context/ModalContext"
import { WelcomeContextProvider } from "./src/context/WelcomeContext"
import ReduxWrapper from "./src/state/ReduxWrapper"
import { ToastList } from "./src/components/ToastList"
import { GrobalModal } from "./src/components/GrobalModal"
import { UserAuthWrapper } from "./src/components/UserAuthWrapper"
import { AutoTransitionToApp } from "./src/components/AutoTransitionToApp"
import { AppWrapper } from "./src/components/AppWrapper"
import { store } from "emoji-mart"

export const wrapRootElement = ({ element }) => {
  return (
    <WelcomeContextProvider>
      <ModalProvider>
        <ToastProvider>
          <ReduxWrapper element={element}></ReduxWrapper>
        </ToastProvider>
      </ModalProvider>
    </WelcomeContextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <span style={{ display: "block" }}>
      <AutoTransitionToApp>
        <UserAuthWrapper>
          <AppWrapper>
            <ToastList />
            <GrobalModal />
            {element}
          </AppWrapper>
        </UserAuthWrapper>
      </AutoTransitionToApp>
    </span>
  )
}

const StaticURLToApplicationScreenNameDict = {
  "/": "Top",
  "/404": "404",
  "/cf_supporter_list": "cfSupporterList",
  "/comment_update": "commentUpdate",
  "/contact": "contact",
  "/home": "home",
  "/like_user_list": "likeUserList",
  "/login": "login",
  "/mypage": "mypage",
  "/notification": "notification",
  "/post_search": "search",
  "/comment_search": "comment_search",
  "/post_form": "postForm",
  // "/post/": "detail",
  "/privacy_policy": "privacyPolicy",
  "/registration": "registration",
  "/tag_management": "tagManagement",
  "/team_info_update": "teamInfoUpdate",
  "/team_info": "teamInfo",
  // "/team_profile/": "teamProfile",
  "/terms": "terms",
  // "/user_profile/": "userProfile",
  "/user_update": "userUpdate",
  "/welcome": "welcome",
  "/news_list": "newsList",
  "/team_usage": "teamUsage",
  "/lead_app": "lead_app",
  "/password/reset": "password/reset",
}

// const DynamicURLToApplicationScreenNameDict = {
// 	"/post/": "detail",
// 	"/team_profile/": "teamProfile",
// 	"/user_profile/": "userProfile",
// }

export const onRouteUpdate = ({ location }) => {
  // console.log( "location", location.pathname )
  // console.log( Object.keys( StaticURLToApplicationScreenNameDict ) )
  const pathName = location.pathname
  if (Object.keys(StaticURLToApplicationScreenNameDict).includes(pathName)) {
    //送信
    logEvent(
      amplitude,
      "Screen-" + StaticURLToApplicationScreenNameDict[pathName],
      { web: true }
    )
  }
  //   const firstPath = extractFirstPath(location.pathname)
  //   if (!GUEST_VIEW_PAGE_LIST.includes(firstPath)) navigate("/welcome")
  // else if() {
  // 	DynamicURLToApplicationScreenNameDict
  // }
}
// export const shouldUpdateScroll = ({ routerProps:{location}, getSavedScrollPosition}) => {
//   // let p = getSavedScrollPosition(routerProps.location)
//   // console.log(routerProps)
//   // console.log(p, routerProps.location)
//   // return false;
//   let po = sessionStorage.getItem(`@@${location.key}`)
//   console.log({po}, typeof po, location.key)
//   const { pathname } = location
//   return [100,100]
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     window.scrollTo(0, 0)
//   }

//   return false
// };

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return getSavedScrollPosition(location)
}
