import React, { FC, CSSProperties } from 'react'
import { FiX } from "react-icons/fi"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const CloseIcon: FC<Props> = ( { onClick, className, style } ) =>
    <FiX className={ className } style={ { cursor: "pointer", ...style } } onClick={ () => onClick && onClick() } />
