import React, { useState, useEffect, useRef } from 'react'
import { healthCheck } from "../apis/maintenance"
import Amplitude, { logEvent } from '../Analytics';
import { Link } from 'gatsby';
import { globalHistory } from "@reach/router"

const MAX_RETRY_NUM = 3

export const useDetectMaintenance = () => {

    const [ isMaintenance, setIsMaintenance ] = useState( false );
    const [ showMaintenanceScreen, setShowMaintenanceScreen ] = useState( true );
    const [ retryCount, setRetryCount ] = useState( 0 );
    const retryCountRef = useRef( retryCount )
    useEffect( () => {
        retryCountRef.current = retryCount
    }, [ retryCount ] )

    useEffect( () => {
        isMaintenance && logEvent( Amplitude, "getIsMaintenace" )
    }, [ isMaintenance ] )

    useEffect( () => {
        window.location.pathname === "/" && setShowMaintenanceScreen( false )
        globalHistory.listen( ( { action } ) => {
            if ( action !== 'PUSH' ) return
            window.location.pathname === "/" ?
                setShowMaintenanceScreen( false ) :
                setShowMaintenanceScreen( true )
        } )
    }, [] )


    const healthcheckRequest = async () => {
        try {
            await healthCheck()
        }
        catch ( error ) {
            if ( retryCountRef.current < MAX_RETRY_NUM ) {
                healthcheckRequest()
                setRetryCount( prevCount => prevCount + 1 )
                return
            }
            else {
                if ( !error.response ) {
                    alert( "何らかの通信障害が発生しました\n通信環境をご確認ください" )
                }
                else {
                    setIsMaintenance( true )
                }
            }
        }
    }

    const renderMentenanceScreen = () =>
        isMaintenance && showMaintenanceScreen ?
            <div style={ {
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
                top: 0, right: 0, bottom: 0, left: 0,
                zIndex: 10,
                backgroundColor: "#fff"
            } }>
                <div>
                    <p style={ { fontWeight: "bold", fontSize: 18 } }>
                        ただいまメンテナンス中です
                    </p>
                    <p style={ { marginTop: 8, marginBottom: 120, color: "rgba(0,0,0,0.54)" } }>
                        しばらく経ってからご利用ください
                    </p>
                    <p style={ { textAlign: "center" } }>
                        <Link to="/">トップページはこちら</Link>
                    </p>
                </div>
            </div> :
            null

    return {
        renderMentenanceScreen
    }
}
