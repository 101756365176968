export function changeMillisec2Minute( time_second: number ) {
    if( time_second < 0 ) return '0:00'
    time_second = Math.floor( time_second )
    const min = Math.floor( time_second / 60 )
    const sec = Math.floor( time_second % 60 )
    return min + ':' + ( '00' + sec ).slice( -2 )
}

export const change_second_to_japanese = (second: number) => {
    const min = Math.floor( second / 60 )
    const sec = Math.floor( second % 60 )
    return `${ min }分 ${ sec }秒`
}

export const extractFirstPath = ( path: string ) => {
    if ( path === "/" ) return "/"
    let newPath = path
    if ( path.slice( -1 ) === "/" ) {
        newPath = newPath.slice( 0, -1 )
    }
    return "/" + newPath.split( "/" )[ 1 ]
}

export const fixBackground = () => {
    document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "hidden"
    document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "hidden"
}

export const releaseBackground = () => {
    document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "auto"
    document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "auto"
}
