import React, { useEffect } from 'react'
import { getAppPostLink } from '../functions/getAppPostLink'

type Props = {
    children: React.ReactNode
}

export const AutoTransitionToApp = ( { children }: Props ) => {

    useEffect( () => {
        if ( window.location.pathname.slice( 0, 6 ) !== "/post/" ) return
        const postID = getPostID()
        const appLink = getAppPostLink(postID)
        if ( !appLink ) return
        window.location.href = appLink
    }, [] )

    const getPostID = () => window.location.pathname.split( "/" )[ 2 ]

    return children
}
