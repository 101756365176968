import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_HOST } from "../constant/const"
import { store } from "../state/ReduxWrapper"

let client: AxiosInstance;

export default client = axios.create( {
    baseURL: `${ API_HOST }`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials:true
} );

client.interceptors.request.use( config => {
    // const { auth0JWT } = store.getState().app
    // if ( auth0JWT ) {
    //     config.headers.Authorization = "Bearer " + auth0JWT
    // }
    return config;
})


client.interceptors.response.use( ( response: AxiosResponse ): AxiosResponse => {
    const data = response.data;
    return { ...response.data, data };
} );

