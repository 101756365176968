import React, { useEffect, useState, createContext, useCallback, useRef } from "react"
import { ToastPosition, ToastType } from "../types/models/Toast";

const DefaultToastParams = {
    text: '',
    backgroundColor: "#fff",
    position: ToastPosition.TOP,
    type: ToastType.INFO,
    visible: false,
    duration: 5000,
    onDismiss: () => { }
};

type ToastContextType = {
    addToast: ( args: Partial<ToastType> ) => void
    toastList: ToastType[]
}

export const ToastContext = createContext( {} as ToastContextType );

export const ToastProvider = ( {
    children
}: {children:JSX.Element} ) => {

    const [ toastList, setToastList ] = useState<ToastType[]>( [] );
    const toastListRef = useRef( toastList )

    useEffect( () => {
        toastListRef.current = toastList
    }, [ toastList ] )

    const addToast = useCallback( args => {

        const toastID = new Date().getTime()
        const newToastList = [ ...toastListRef.current,
        {
            ...DefaultToastParams,
            visible: true,
            toastID,
            ...args,
            removeToast: () => removeToastByToastID( toastID )
        }
        ]
        setToastList( newToastList );
    }, [ toastListRef.current ] );

    const removeToastByToastID = useCallback( removeToastID => {
        const newToastList = toastListRef.current.filter( toast =>
            toast.toastID !== removeToastID )
        setToastList( newToastList.slice() );
    }, [ toastListRef.current ] );


    return (
        <ToastContext.Provider
            value={ {
                addToast,
                toastList: toastList || [],
            } }>
            { children }
        </ToastContext.Provider>
    );
};
