import {useEffect } from 'react'
import { useSelector } from 'react-redux'
import amplitude, { getUserPropertyForAmplitude } from '../Analytics'

export const useUpdateAmplitude = () => {
    const teamData = useSelector( ( state ) => state.app .teamData )
    const isLoggedIn = useSelector( ( state ) => state.app.isLoggedIn )
    const userName = useSelector( ( state ) => state.app.userName )
    useEffect( () => {
        getUserPropertyForAmplitude( amplitude )
    }, [ isLoggedIn, teamData, userName ] )
}
