import { isWebView as isWebViewFunc } from "../utils/isWebView"

export type DeviceType = {
    isWebView: boolean,
    isiOS: boolean,
    isAndroid: boolean,
    isPwa: boolean,
}
export const detectDevice = (): DeviceType => {
    const isWebView = isWebViewFunc()
    let isPwa = window.matchMedia( '(display-mode: standalone)' ).matches || ( window.navigator as any ).standalone
    const isiOS = !!navigator.userAgent.match( /(iPhone|iPad|iPod)/ )
    const isAndroid = !!navigator.userAgent.match( /(Android)/ )
    if ( !isPwa && ( window.screen.height - document.documentElement.clientHeight < 80 ) ) { //PWAだとアドレスバーがないので
        isPwa = true
    }
    return {
        isWebView,
        isiOS,
        isAndroid,
        isPwa,
    }
}
