export const ToastPosition = {
    TOP: "top" as const,
    CENTER: "center" as const,
    BOTTOM: "bottom" as const
}

const ToastPositionList = [ ToastPosition.TOP, ToastPosition.CENTER, ToastPosition.BOTTOM ]
export type ToastPositionType = typeof ToastPositionList[ number ]

export const ToastType = {
    INFO: "info" as const,
    WARN: "warn" as const,
    ERROR: "error" as const,
    SUCCESS: "success" as const
}

const ToastTypeList = [ ToastType.INFO, ToastType.WARN, ToastType.ERROR, ToastType.SUCCESS ]
export type ToastTypeType = typeof ToastTypeList[ number ]

export const ToastSize = {
    MEDIUM: "medium" as const,
    SMALL: "small" as const,
    LARGE: "large" as const
}

const ToastSizeList = [ ToastSize.MEDIUM, ToastSize.SMALL, ToastSize.LARGE ]
export type ToastSizeType = typeof ToastSizeList[ number ]

export type addToastType = ( args: Partial<ToastType> ) => void

export type ToastType = {
    text: string
    position: ToastPositionType
    type: ToastTypeType
    visible: boolean
    duration: number
    size: ToastSizeType
    onDismiss: () => void
    onPress?: () => void
    removeToast: () => void
    toastID: number
    image?: ImageData
}
