import React, { FC, useContext, useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { navigate } from "gatsby"
import { GUEST_VIEW_PAGE_LIST } from "../constant/const"
import ActivityIndicator from './atoms/ActivityIndicator';
import { extractFirstPath } from '../utils/utilFunctions';
import { WelcomeContext } from '../context/WelcomeContext';
import { checkAuhtentication } from '../apis/user';
import amplitude, { getUserPropertyForAmplitude } from '../Analytics';
import { useUpdateAmplitude } from '../hooks/useUpdateAmplitude';


type Props = {
    children: React.ReactNode
}

export const UserAuthWrapper: FC<Props> = ( { children } ) => {

    const [ isProcessingAuth, setIsProcessingAuth ] = useState( true );
    const { setTeamDone } = useContext( WelcomeContext )
    useUpdateAmplitude()

    const dispatch = useDispatch();

    useEffect( () => {
        getIsAuthenticated()
    }, [] )

    const getIsAuthenticated = async () => {
        const firstPath = extractFirstPath( window.location.pathname )
        const canGuestView = GUEST_VIEW_PAGE_LIST.includes( firstPath ) || GUEST_VIEW_PAGE_LIST.includes( window.location.pathname )

        setIsProcessingAuth( true )
        try {
            const { data } = await checkAuhtentication()
            setIsProcessingAuth( false )
            localStorage.removeItem( "userToken" )
            localStorage.setItem( "userID", data.id )
            dispatch( { type: "setIsLoggedIn", isLoggedIn: true } )
            dispatch( { type: "setUserUuid", userUuid: data.id } )
            dispatch( { type: "setHasCompletedRegistration", hasCompletedRegistration: data.has_completed_registration } )
            dispatch( { type: "setTeamData", teamData: data.team_data } )
            dispatch( { type: "setUserName", userName: data.name } )
            const teamJoined = !!data.team
            getUserPropertyForAmplitude( amplitude )
            setTeamDone( teamJoined )
            if ( window.location.pathname.includes( "/welcome/" ) ) {
                navigate( "/welcome" )
            }
            else if ( !canGuestView && !data.has_completed_registration ) {
                navigate( "/welcome" )
            }
        }
        catch ( e ) {
            console.log( e )
            dispatch( { type: "setIsLoggedIn", isLoggedIn: false } )
            setIsProcessingAuth( false )
            if ( e.response.status == 401 ) {
                const callbackURL =
                    ( window.location.pathname.includes( "welcome" ) || window.location.pathname.includes( "login" ) ) ?
                        "" :
                        window.location.pathname
                const loginPath = callbackURL ?
                    `/login?callback=${ callbackURL }` :
                    "/login"
                !canGuestView && setTimeout( () => navigate( loginPath ), 1000 )
            }
        }
    }

    const firstPath = extractFirstPath( window.location.pathname )
    const canGuestView = GUEST_VIEW_PAGE_LIST.includes( firstPath )

    return (
        <div style={ { position: "relative" } }>
            <div style={ {
                position: "absolute",
                top: 0,
                left: 0,
                display: !canGuestView && isProcessingAuth ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 15,
            } }>
                <div>
                    <p style={ { marginBottom: 12, color: "#fff" } }>ログイン処理中です…</p>
                    <ActivityIndicator white />
                </div>
            </div>
            <>
                { children }
            </>
        </div>
    )
}
