import React, { useEffect, FC, useState, createContext, useCallback, useRef, ReactPortal } from "react"

type ModalContentType = JSX.Element | null

type ModalContextType = {
    modalVisible: boolean,
    setModalVisible: ( bool: boolean ) => void,
    modalContent: () => ModalContentType,
    setModalContent: ( content: () => ModalContentType ) => void
}

export const ModalContext = createContext( {} as ModalContextType );


export const ModalProvider = ( {
    children
}: { children: JSX.Element } ) => {

    const [ modalVisible, setModalVisible ] = useState( false );
    const [ modalContent, setModalContent ] = useState<() => ModalContentType>( () => null );

    return (
        <ModalContext.Provider
            value={ {
                modalVisible,
                setModalVisible,
                modalContent,
                setModalContent
            } }>
            { children }
        </ModalContext.Provider>
    );
};
