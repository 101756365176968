import { store } from "./state/ReduxWrapper"
import { ADMIN_USER_PK_LIST } from "./constant/const"
import { GlobalStateType } from "./state/app"


const isAdminUser = ( userID: string | null ) => userID && ADMIN_USER_PK_LIST.includes( userID )

const amplitudeDamy = {
    getInstance: () => {
        return ( {
            init: () => { },
            logEvent: () => { },
            setUserId: () => { },
            setUserProperties: () => { }
        } )
    }
}
const amplitude = typeof window !== 'undefined' ?
    require( "amplitude-js" ) :
    amplitudeDamy

const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY
amplitude.getInstance().init( AMPLITUDE_API_KEY );
getUserPropertyForAmplitude( amplitude )


async function logEvent ( amplitude: any, eventName: string, optionProperty = {} ) {

    const userID = localStorage.getItem( "userID" )
    if ( isAdminUser( userID ) ) return

    amplitude.getInstance().logEvent( eventName, {
        web: true,
        ...optionProperty
    } )
    window.gtag && window.gtag( "event", eventName, optionProperty );
}

async function logErrorEvent ( amplitude: any, eventName: string, error: any, optionProperty = {} ) {

    const userID = localStorage.getItem( "userID" )
    if ( isAdminUser( userID ) ) return

    amplitude.getInstance().logEvent( eventName, {
        statusCode: error.response.status,
        data: error.response.data,
        web: true,
        ...optionProperty
    } )
    window.gtag && window.gtag( "event", eventName, optionProperty );
}

async function getUserPropertyForAmplitude ( amplitude: any ) {

    const userID = localStorage.getItem( "userID" )
    if ( isAdminUser( userID ) ) return

    const { isLoggedIn } = store.getState().app as GlobalStateType
    if ( !isLoggedIn ) {
        amplitude.clearUserProperties()
        return amplitude
    }

    amplitude.getInstance().setUserId( userID )

    const { teamData } = store.getState().app as GlobalStateType

    const identify = teamData ?
        new amplitude.Identify()
            .set( "teamID", teamData.id )
            .set( "teamName", teamData.name )
            .set( "teamSports", teamData.sports ) :
        new amplitude.Identify()
    amplitude.getInstance().identify( identify );
    return amplitude

}
export { getUserPropertyForAmplitude, logEvent, logErrorEvent }
export default amplitude
