export const API_HOST = process.env.API_HOST
export const BACK_BUTTON_STR = "backButton" as const
export const SAMPLE_POST_LIST = [
    {
        "pk": "3b9f8868-fe05-4d33-8062-ec925f739c59",
        // "text": <SAMPLE_POST_LISTItemText text="コーチによるフォローアップ例" />,
        "text": "コーチによるフォローアップのサンプル",
        "originalText": "ハイライト2020.02.15",
        "file_type": 2,
        "thumbnail": null,
        "file": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/file/8aa27778-abc1-447f-93cc-6d16f54d0b6ce60465d3-9ad2-4cd0-84e3-e9ef13a8520b.jpg",
        "comment_number": 8,
        "how_old": "",
        "created_at": "2019-03-01T00:51:44.667000+09:00",
        "output_author": {
            "name": "OUGCコーチ",
            "pk": 16,
            "profile_image_100": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/c9fbb1b58220062c7122af21d9842d2b.jpg",
            "profile_image_400": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/033238834d63b889581765912546a4b1.jpg",
        }
    },
    {
        "pk": "1b707d3d-3cd6-42d6-87156a245eb225bd",
        // "text": <SAMPLE_POST_LISTItemText text="コーチによる練習コンテンツ例" />,
        "text": "コーチによる練習コンテンツのサンプル",
        "originalText": "男子2019基礎アップ",
        "file_type": 2,
        "thumbnail": null,
        "file": "https://d2ml46mqa54h0p.cloudfront.net/media/4/file/%E7%94%B7%E5%AD%90%E3%82%A2%E3%83%83%E3%83%97.png",
        "comment_number": 5,
        "how_old": "",
        "created_at": "2019-03-01T00:51:44.667000+09:00",
        "output_author": {
            "name": "OUGCコーチ",
            "pk": 16,
            "profile_image_100": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/c9fbb1b58220062c7122af21d9842d2b.jpg",
            "profile_image_400": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/033238834d63b889581765912546a4b1.jpg",
        }
    },
    {
        "pk": "b82ff447-74c6-4131-95ea52657d021a8c",
        // "text": <SAMPLE_POST_LISTItemText text="練習日記サンプル" />,
        "text": "選手による練習日記のサンプル",
        "originalText": "後ろ降り上がり倒立(屈腕)成長記録",
        "file_type": 1,
        "file": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/file/VID_20190824_175645_s016ce5241b-ca70-4de8-aea7-48b843022fc9.mp4",
        "thumbnail": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/thumbnail/VID_20190824_175645_s016ce5241b-ca70-4de8-aea7-48b843022fc9_00001.jpg",
        "comment_number": 4,
        "how_old": "",
        "created_at": "2019-03-01T00:51:44.667000+09:00",
        "output_author": {
            "name": "わたなべひであき",
            "pk": 20,
            "profile_image_100": null,
            "profile_image_400": null
        }
    }
]

// export const PostSaveLimit = 30 * 24 * 60 * 60 * 1000
export const POST_SAVE_INTERVAL = 30 * 1000
export const COMMENT_SAVE_LIMIT = 30 * 24 * 60 * 60 * 1000
export const COMMENT_SAVE_INTERVAL = 15 * 1000


export const GUEST_VIEW_PAGE_LIST = [
    "/",
    "/post",
    "/404",
    "/help",
    "/cf_supporter_list",
    "/contact",
    "/like_user_list",
    "/login",
    "/privacy_policy",
    "/registration",
    "/team_usage",
    "/team_profile",
    "/user_profile",
    "/terms",
    "/news_list",
    "/video",
    "/video_comparison_for_webview",
    "/video_draw_model_for_webview",
    "/video_draw_model_for_android_webview",
    "/lead_app",
    "/password/reset/"
]

export const EMOJI_I18n = {
    search: '検索',
    clear: 'キャンセル', // Accessible label on "clear" button
    notfound: '見つかりませんでした',
    skintext: '色を選んでください',
    categories: {
        search: '検索結果',
        recent: 'よく使う絵文字',
        smileys: '顔文字と感情',
        people: '人と体',
        nature: '動物と自然',
        foods: '食べ物と飲み物',
        activity: 'アクティビティ',
        places: '旅行と場所',
        objects: '物',
        symbols: '記号',
        flags: '旗',
        custom: 'カスタム絵文字',
    },
    categorieslabel: '絵文字カテゴリー', // Accessible title for the list of categories
    skintones: {
        1: 'デフォルト',
        2: '薄い肌色',
        3: 'やや薄い肌色',
        4: '中間の肌色',
        5: 'やや濃い肌色',
        6: '濃い肌色',
    },
}

export const JAPANESE_DICT = {
    "Post": "投稿",
    "Comment": "コメント",
    "like": "いいね！",
    "reaction": "リアクション"
}

export const ADMIN_USER_PK_LIST = [
    "1fa6296f-db36-4c3a-87d6-258fc2c6167d",
    "39a7621b-e1e1-4618-89f5-447ea9585f61",
    "66fa864d-6d02-4147-beac-3c159d958a97",
    "a93ad39a-1bba-4ee7-9672-93e46742305b"
]
