import { AxiosPromise } from 'axios';
import { API_HOST } from '../constant/const';
import { TeamDataType } from '../types/models/Team';
import client from './client';

type updateUserOnWelcomeResponse = {
    detail: string
    data: UserAuthType
}

type updateUserOnWelcomeParams = {
    userID: string,
    params: {
        last_name: string
        purpose_for_this_service: string
    }
}

export const updateUserOnWelcome = (
    { userID, params }: updateUserOnWelcomeParams ): AxiosPromise<updateUserOnWelcomeResponse> => {
    const data = new FormData()
    data.append( "last_name", params.last_name )
    data.append( "purpose_for_this_service", params.purpose_for_this_service )
    return client.patch( `/api/users/${ userID }/`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    } )
}

type UserAuthType = {
    token: string,
    name: string,
    id: string,
    team: string | null,
    team_data: TeamDataType | null,
    has_completed_registration: boolean
}

type loginParams = {
    email: string
    password: string
}

export const login = ( { email, password }: loginParams ): AxiosPromise<UserAuthType> => {
    const qs = require( 'qs' );
    const params = qs.stringify( {
        username: email,
        password,
    } )
    return client.post( `/token/`, params, {
        headers: ( {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Referer': API_HOST,
        } ),
    } )
}

type registerParams = {
    email: string
    password: string
}

export const register = ( { email, password }: registerParams ): AxiosPromise<UserAuthType> => {
    const qs = require( 'qs' );
    const params = qs.stringify( {
        email,
        password,
    } )
    return client.post( `api/user_registration/`, params, {
        headers: ( {
            'Content-Type': 'application/x-www-form-urlencoded',
        } ),
    } )
}

export const checkAuhtentication = (): AxiosPromise<UserAuthType> => client.get( '/is_authenticated' )


type setPasswordResponse = {
    detail: string
}

type setPasswordParams = {
    newPassword: string
    uid: string
    token: string
}

export const setPassword = ( { newPassword, uid, token }: setPasswordParams ): AxiosPromise<setPasswordResponse> => {
    const data = new FormData()
    data.append( "newPassword", newPassword )
    return client.post( `api/users/password/confirm/${ uid }/${ token }/`, data )
}
