import React, { CSSProperties, FC, useEffect } from "react"
import Styles from "../styles/Modal.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSwipeable } from "react-swipeable";
import { CloseIcon } from "../icons/CloseIcon";

type Props = {
    visible: boolean,
    closeModal: () => void,
    children: JSX.Element,
    closeModalIconVisible?: boolean,
    style?: CSSProperties,
    closeIconStyle?: CSSProperties
    swipeToClose?: boolean
}

const Modal: FC<Props> = ( {
    visible,
    closeModal,
    children,
    closeModalIconVisible = true,
    style,
    closeIconStyle,
    swipeToClose
} ) => {

    const swipeHandlers = useSwipeable( {
        delta: 120,
        onSwipedUp: () => window.visualViewport.scale == 1 && closeModal(),
        onSwipedDown: () => window.visualViewport.scale == 1 &&closeModal(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    } );

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "hidden"
    }

    const releaseBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflowY = "auto"
        document.getElementsByTagName( "body" )[ 0 ].style.overflowY = "auto"
    }

    // useEffect( () => {
    //     fixBackground()
    //     return () => releaseBackground()
    // }, [] )

    // useEffect( () => {
    //     visible ?
    //         fixBackground() :
    //         releaseBackground()
    // }, [ visible ] )

    return (
        <div
            className={ Styles.container }
            style={ {
                display: visible ?
                    "flex" :
                    "none",
                ...style
            } }
            { ...( swipeToClose ? swipeHandlers : {} ) }
        >
            <div className={ Styles.modal_content }>
                <div
                    className={ Styles.icon_container }
                    style={ closeModalIconVisible ?
                        {} :
                        { display: "none" } }>
                    <CloseIcon
                        className={ Styles.icon }
                        style={ closeIconStyle }
                        onClick={ () => closeModal() } />
                </div>
                { children }
            </div>
        </div> )
}

export default Modal
