
import React, { useContext } from "react"
import Modal from "./Modal"
import { ModalContext } from "../context/ModalContext"


export const GrobalModal = () => {
    
    const { modalContent, modalVisible, setModalVisible } = useContext( ModalContext );

    return (
        <Modal 
            visible={ modalVisible } 
            closeModal={ () => setModalVisible( false ) }>
            { modalContent as unknown as JSX.Element }
        </Modal>
    )
}
