import React, { FC, useEffect, useRef, useState } from "react"
import Styles from "../styles/Toast.module.scss";
import clsx from "clsx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ToastSizeType, ToastType, ToastTypeType } from "../types/models/Toast";
import { InfoCircleIcon } from "../icons/InfoCircleIcon";
import { CheckCircleIcon } from "../icons/CheckCircleIcon";

const HideDuration = 300

type Props = {
    text: string,
    type: ToastTypeType,
    size:ToastSizeType,
    removeToast: () => void,
    duration: number,
    onClick?: () => void,
    onDismiss?: () => void
}

export const Toast: FC<Props> = ( {
    text,
    type,
    size,
    removeToast,
    duration,
    onClick,
    onDismiss
} ) => {

    const toastRef = useRef<HTMLDivElement>(null)
    const [ visible, setVisible ] = useState( false );
    const visibleRef = useRef( visible )

    useEffect( () => {
        visibleRef.current = visible
    }, [ visible ] )

    useEffect( () => {
        if ( !duration ) return
        setVisible( true )
        setTimeout( () => {
            visibleRef.current && fadeOut()
        }, duration )
    }, [] )

    const fadeOut = () => {
        setVisible( false )
        setTimeout( () => {
            onDismiss && onDismiss()
            removeToast()
        }, HideDuration )
    }



    const renderIcon = () => {
        if ( type === ToastType.INFO ) return <InfoCircleIcon className={ Styles.icon } />
        else if ( type === ToastType.SUCCESS ) return <CheckCircleIcon className={ Styles.icon } />
        else if ( type === ToastType.WARN ) return <FontAwesomeIcon icon={ faExclamationTriangle } className={ Styles.icon } />
        else if ( type === ToastType.ERROR ) return <FontAwesomeIcon icon={ faExclamationCircle } className={ Styles.icon } />
    }


    return (
        <div
            ref={ toastRef }
            onClick={ () => { fadeOut(); onClick && onClick() } }
            className={ clsx(
                Styles.container,
                visible && Styles.visible,
                size && Styles[ size ]
            ) }>
            <div className={ clsx( Styles.content, type && Styles[ type ] ) }>
                <div className={ clsx( Styles.icon_wrapper, Styles[ size ]) }>
                    { renderIcon() }
                </div>
                <div className={ clsx( Styles.text, size && Styles[ size ]) }>
                    { text }
                </div>
            </div>
        </div> )
}
