import React, { CSSProperties, FC } from "react"
import Styles from "../../styles/ActivityIndicator.module.scss";

type ActivityIndicatorProps = {
    style?: object,
    ballStyle?: CSSProperties
    white?: boolean
}

const ActivityIndicator: FC<ActivityIndicatorProps> = ( {
    style,
    ballStyle,
    white
} ) => {
    return (
        <div className={ `${ Styles.loading_balls_wrap }` } style={ style }>
            <div className={ `${ Styles.balls_loading }` }>
                <span className={ `${ Styles.ball } ${ Styles.ball_1 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_2 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_3 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_4 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_5 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_6 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_7 } ${ white && Styles.white }` } style={ ballStyle }></span>
                <span className={ `${ Styles.ball } ${ Styles.ball_8 } ${ white && Styles.white }` } style={ ballStyle }></span>
            </div>
        </div>
    )
}

export default ActivityIndicator
