import React, { FC } from 'react'
import { useDetectMaintenance } from '../hooks/useDetectMaintenance'

type Props = {
}

export const AppWrapper: FC<Props> = ( { children } ) => {

    const { renderMentenanceScreen } = useDetectMaintenance()

    return (
        <>
            {children }
            {renderMentenanceScreen() }
        </>
    )
}
