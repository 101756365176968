import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import rootReducer from '.';

const createStore = () => reduxCreateStore( rootReducer );
export const store = createStore()

export default ( {
    element
}: { element: JSX.Element } ) => (
        <Provider store={ store }>{ element }</Provider>
    );
