module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ClubCloud","short_name":"ClubCloud","start_url":"/home","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/images/club_cloud_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"68f23f22b3c4193a19266dc4377354f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2P6FW3TGQB"],"pluginConfig":{"head":true,"exclude":["/video","/video_comparison_for_webview","/video_draw_model_for_webview","/video_draw_model_for_android_webview"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
