import React, { useState, createContext, FC, useEffect } from 'react'

type WelcomeContextType = {
    name: string
    setName: ( name: string ) => void
    answers: AnswerType[]
    setAnswers: ( answers: AnswerType[] ) => void
    anotherAnswer: string
    setAnotherAnswer: ( anotherAnswer: string ) => void
    teamDone: boolean
    setTeamDone: ( teamDone: boolean ) => void
    resetWelcomeContext: () => void
}

type AnswerType = {
    label: string
    isSelected: boolean
}

const InitialAnswers = [
    { label: "チーム内でアドバイスが欲しい", isSelected: false },
    { label: "チーム内で参考になる動画を見つけたい", isSelected: false },
    { label: "チームメンバーにうまくいったところを見て欲しい", isSelected: false },
    { label: "チームメンバーの役に立てたら嬉しい", isSelected: false },
    { label: "動画を後で見返したい", isSelected: false }
]

export const WelcomeContext = createContext<WelcomeContextType>( {} as WelcomeContextType )

type Props = {
    teamJoined:boolean
    children: React.ReactNode
}
export const WelcomeContextProvider: FC<Props> = ( { teamJoined, children } ) => {
    const [ name, setName ] = useState( "" );
    const [ anotherAnswer, setAnotherAnswer ] = useState( "" );
    const [ answers, setAnswers ] = useState( InitialAnswers );
    const [ teamDone, setTeamDone ] = useState( false );
    const resetWelcomeContext = () => {
        setName( "" )
        setAnotherAnswer( "" )
        setTeamDone( false )
        setAnswers( InitialAnswers )
    }

    useEffect( () => {
        setTeamDone( teamJoined )
    }, [ teamJoined ] )

    return (
        <WelcomeContext.Provider value={ {
            name, setName,
            teamDone, setTeamDone, answers, setAnswers,
            anotherAnswer, setAnotherAnswer, resetWelcomeContext
        } }>
            { children }
        </WelcomeContext.Provider> )
}
