import React, { useContext, useMemo } from "react"
import Styles from "../styles/ToastList.module.scss";
import { ToastContext } from "../context/ToastContext"
import clsx from "clsx"
import { Toast } from "./Toast"
import { ToastPosition } from "../types/models/Toast";

export const ToastList = () => {

    const { toastList } = useContext( ToastContext );

    const TopToast = useMemo( () => {
        if( !toastList ) return null
        const position = ToastPosition.TOP
        return (
            <div className={ clsx( Styles.container, Styles[ position ] ) }>
                { toastList
                    .filter( toast  => toast.position === ToastPosition.TOP)
                    .map( toast  => <Toast { ...toast } key={ toast.toastID } /> ) }
            </div>
        );
    }, [ toastList ] )


    const CenterToast = useMemo( () => {
        if( !toastList ) return null
        const position = ToastPosition.CENTER
        return (
            <div className={ clsx( Styles.container, Styles[ position ] ) }>
                { toastList
                    .filter( toast  => toast.position === ToastPosition.CENTER)
                    .map( toast  => <Toast { ...toast } key={ toast.toastID } /> ) }
            </div>
        );
    }, [ toastList ] )

    const BottomToast = useMemo( () => {
        if( !toastList ) return null
        const position = ToastPosition.BOTTOM
        return (
            <div className={ clsx( Styles.container, Styles[ position ] ) }>
                { toastList
                    .filter( toast  => toast.position === ToastPosition.BOTTOM)
                    .map( toast  => <Toast { ...toast } key={ toast.toastID } /> ) }
            </div>
        );
    }, [ toastList ] )

    return (
        <div>
            { TopToast }
            { CenterToast }
            { BottomToast }
        </div>
    )
}
